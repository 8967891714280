import { Coupon } from '@/domain/loyalty/CustomerLoyalty';

export type PostMessageHandler = (event: MessageEvent<unknown>) => void;
export type LoyaltyToggle = 'on' | 'off';
export type RewardsMessage = {
  height: number;
  scroll: string;
  loyalty_toggle: LoyaltyToggle;
  coupon: {
    key: Coupon['rewardsRedemptionCode'];
    action?: string;
  };
};

export type YumRewardsMessage = {
  redeemedItem: {
    key: string; // Loyalty offer id
    action: 'view' | 'add';
  };
};

export enum RewardAction {
  view = 'view',
  add = 'add'
}
