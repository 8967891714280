/* eslint-disable default-case */
import { Dispatch, SetStateAction, useEffect } from 'react';
import router from 'next/router';
import { useDispatch } from 'react-redux';
import { LoyaltyState, T3IframeEvents } from '../constants';
import {
  RewardsMessage,
  PostMessageHandler,
  LoyaltyToggle,
  RewardAction,
  YumRewardsMessage
} from '../T3Iframe.types';
import { RedeemLoyaltyRewards } from '@/rewards/t3Iframe/hooks/useRedeemLoyaltyReward';
import Routes from '@/router/routes';
import { toggleRail } from '@/rail/slices/Rail.slice';
import { RailType } from '@/rail/slices/Rail.slice.types';
import { onAccountRewardsClickSignIn } from '@/dataAnalytics/dataAnalyticsHelper';
import useAnalytics, { Analytics } from '@/dataAnalytics/hooks/useAnalytics';
import { openCartRail } from '@/clientCore/redux/rail/CartRailSlice';

const eventNotFromIframe = (
  event: MessageEvent<unknown>,
  iframeUrl: URL
): boolean => !event.origin.includes(iframeUrl.hostname);

const preventMessagesFromUnknown = (iframeUrl: URL, handler: PostMessageHandler) => (event: MessageEvent<unknown>) => {
  if (eventNotFromIframe(event, iframeUrl)) {
    return;
  }

  handler(event);
};

const isResizeMessage = (message: unknown): message is RewardsMessage => (message as RewardsMessage).height !== undefined;

const isScrollTop = (message: unknown): message is RewardsMessage => (message as RewardsMessage).scroll === 'parent_top';

const isLoyaltyToggleMessage = (
  message: unknown,
  toggleState: LoyaltyToggle
): message is RewardsMessage => (message as RewardsMessage).loyalty_toggle === toggleState;

const isCouponMessage = (message: unknown): message is RewardsMessage => (message as RewardsMessage).coupon !== undefined;

const isYumRedemptionMessage = (message: unknown): message is YumRewardsMessage => (message as YumRewardsMessage).redeemedItem !== undefined;

export const rewardsListener = (
  iframeUrl: URL,
  setIframeHeight: Dispatch<SetStateAction<number>>,
  handleLoyaltyToggle: (toggle: LoyaltyToggle) => void,
  redeem: RedeemLoyaltyRewards['redeem'],
  view: () => void,
  analytics: Analytics,
  dispatch?: Dispatch<unknown>
): PostMessageHandler => preventMessagesFromUnknown(iframeUrl, (event) => {
  if (event.data === T3IframeEvents.SIGN_IN) {
    analytics.push(() => onAccountRewardsClickSignIn());
    // Sets url param for signin redirect and opens signin rail
    router.push('/account/join/?afterAuth=rewards', undefined, {
      shallow: true
    });

    if (dispatch) dispatch(toggleRail(RailType.SIGN_IN));
  }

  if (event.data === T3IframeEvents.CREATE_ACCOUNT) {
    analytics.push(() => onAccountRewardsClickSignIn());
    // Check if there are query params in the URL
    const queryParamsInUrl = window.location.search.slice(1);
    // Construct the new route with query params
    const newRoute = `${Routes.ACCOUNT.CREATE_ACCOUNT}${queryParamsInUrl ? `?${queryParamsInUrl}` : ''}`;
    router.push(newRoute);
  }

  if (isCouponMessage(event.data)) {
    switch (event.data.coupon.action) {
      case RewardAction.view: return view();
      default: redeem(event.data.coupon.key);
    }
  }

  if (isResizeMessage(event.data)) {
    setIframeHeight(event.data.height);
  }

  if (isScrollTop(event.data)) {
    window.scrollTo(0, 0);
  }

  if (isLoyaltyToggleMessage(event.data, LoyaltyState.OFF)) {
    handleLoyaltyToggle(LoyaltyState.OFF);
  }

  if (isLoyaltyToggleMessage(event.data, LoyaltyState.ON)) {
    handleLoyaltyToggle(LoyaltyState.ON);
  }

  // Yum iframe events events
  if (isYumRedemptionMessage(event.data)) {
    if (event.data.redeemedItem.action === 'add') {
      redeem(event.data.redeemedItem.key, event);
    } else if (dispatch) dispatch(openCartRail());
  }

  return undefined;
});

const useRewardsListener = (
  iframeUrl: URL,
  setIframeHeight: Dispatch<SetStateAction<number>>,
  handleLoyaltyToggle: (toggle: LoyaltyToggle) => void,
  redeem: RedeemLoyaltyRewards['redeem'],
  view: () => void
): void => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  useEffect(() => {
    const listener = rewardsListener(
      iframeUrl,
      setIframeHeight,
      handleLoyaltyToggle,
      redeem,
      view,
      analytics,
      dispatch
    );
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [iframeUrl, setIframeHeight, handleLoyaltyToggle, redeem, view, analytics, dispatch]);
};

export default useRewardsListener;
